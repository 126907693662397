import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, timer, Subject } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { AUTH_SERVICE, AuthService } from "../authservice";
import { FtapiService, FileSubmission, CompletedPart, NetworkService, Network, StatusMessage, XmlError } from "ftapi";
import { network, destNetworks } from '../../networks/network';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SubmitComponent {

  @ViewChild('fileselector') fileselectorVariable!: ElementRef;

  // File transfer destination variable
  destNetworks: Network[] = [];
  destNetwork!: Network;

  // Generic error display vars
  listVerErrormsgs: string[] = [];
  formErrors: Array<string> = [];
  // When the list was last refreshed.
  lastRefreshTime: Date | null;
  // Error: file is too big
  filesize_error: Boolean = false;
  largefilename: string = '';
  // Chunksize for file chunking (64)
  private readonly chunksize: number = 2 ** 26;

  // Status messages
  statuses: Array<StatusMessage> = [];

  // Progress bar variables
  isLoading: Boolean = false;
  transferProgress: number | undefined;
  fileBeingTransferred: string | undefined;

  // Timers to display the token refresh message.
  everyMinute: Observable<number> = timer(0, 60 * 1000);
  private lastRefresh: number;
  needsRefresh: boolean = false;

  // Observable to trigger a ListObjectVersions refresh.
  private refreshObjectVersions: Subject<number> = new Subject();
  // When the page should be refreshed.
  private nextRefresh: Date;
  // How long to increase the refresh duration by, from the latest submission.
  private readonly refreshBackoff: number = 1;
  // The initial delay for refresh in seconds, after submission.
  private readonly minRefreshDelay: number = 4;
  private readonly DATE_MAX: Date = new Date(8640000000000000);

  // For the transfer listing
  listVersionsCols: Array<string> = ["date", "dest_network", "filename", "filesize", "tx_status"];
  listVersions = new MatTableDataSource<FileSubmission>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // For the successful snackbar
  private horizontalPosition: MatSnackBarHorizontalPosition = "right";
  private verticalPosition: MatSnackBarVerticalPosition = "bottom";

  // The default page size for pagination.
  private readonly defaultPageSize: number = 5;

  constructor(
    @Inject(AUTH_SERVICE) private authService: AuthService,
    private ftapi: FtapiService,
    private networkService: NetworkService,
    private _snackbar: MatSnackBar,
    ) {
    this.lastRefreshTime = null;
    this.lastRefresh = Date.now();
    this.nextRefresh = this.DATE_MAX;
  }

  ngOnInit() {
    this.destNetworks = this.networkService.enabledNetworks(network, destNetworks);
    this.destNetworks = this.destNetworks.filter(({ enabled }) => enabled === true);
    this.destNetwork = this.destNetworks[0];

    this.lastRefresh = Date.now();
    console.log("Refreshed the page at " + new Date(this.lastRefresh).toLocaleString());

    this.everyMinute.subscribe((seconds) => {
      let jwtValidity = environment.msal_enabled ? 23 * 60 * 60 * 1000 : 25 * 60 * 1000;
      if ((Date.now() - this.lastRefresh) > jwtValidity ) {
        this.needsRefresh = true;
      }
    });

    this.refreshObjectVersions.subscribe(() => {
      this.autoLoadObjectVersions(this.nextRefresh)
    });

    this.updateStatus();
    this.loadObjectVersions();

  }

  ngAfterViewInit(){
    this.paginator.page.subscribe(
      (pageEvent) => {
        window.localStorage.setItem("pageSizePreference", pageEvent.pageSize.toString());
      }
    )
  }

  getPageSizePreference(): number {
    let pageSizePreference = window.localStorage.getItem("pageSizePreference");
    if (pageSizePreference !== null) {
      let parsedPref = Number.parseInt(pageSizePreference);
      if (!Number.isNaN(parsedPref)) {
        return parsedPref;
      }
    }
    return this.defaultPageSize;
  }

  getRelativeLastRefresh(): string {
    dayjs.extend(relativeTime);
    return dayjs().to(this.lastRefreshTime);
  }

  // Validate the selected files and save them to a variable.
  selectFile(event: any): void {
    this.formErrors = [];
    for (const file of event.target.files) {
      if (file.size > 50 * 2 ** 30) {
        this.largefilename = file.name;
        this.formErrors.push(`File ${file.name} is over 50GiB.`);
        this.fileselectorVariable.nativeElement.value = '';
      }
    }
  }

  async updateStatus(): Promise<void> {
    this.statuses = await this.ftapi.getStatus();
  }

  async submitFileTransfer(currFile: File) {
    try {
      await this.authService.updateToken();
    } catch (e) {
      console.error("Failed to refresh token: ", e);
    }

    let jwt_username = await this.authService.getToken();
    let jwt = jwt_username[0];
    let username = jwt_username[1];
    let bucket = username + ".personal." + this.destNetwork.value;

    let attempt_snackbar = this._snackbar.open(
      "Transferring " + currFile.name,
      "",
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      }
    );
    try {
      // upload using put object if filesize < chunksize, otherwise use multipart upload
      let response!: Response;
      if (currFile.size < this.chunksize) {
        response = await this.ftapi.putObject(jwt, bucket, currFile.name, currFile);
      } else {
        let uploadId = await this.ftapi.createMultipartUpload(jwt, bucket, currFile.name);
        let totalChunks = Math.ceil(currFile.size/this.chunksize);
        let nChunk = 0;
        this.transferProgress = 0;
        this.fileBeingTransferred = currFile.name;

        let parts: Array<CompletedPart> = new Array();
        while (nChunk < totalChunks) {
          this.transferProgress = nChunk/totalChunks * 100;
          try {
            await this.authService.updateToken();
          } catch (e) {
            console.error("Failed to refresh token: ", e);
          }
          let jwt_username = await this.authService.getToken();
          let jwt = jwt_username[0];
          let username = jwt_username[1];
          let chunkStart = nChunk * this.chunksize;
          let chunkEnd = (nChunk + 1) * this.chunksize;
          let currChunk = currFile.slice(chunkStart, chunkEnd);
          let eTag = await this.ftapi.uploadPart(jwt, bucket, currFile.name, nChunk + 1, uploadId, currChunk);

          if (eTag === null) {
            console.error("UploadPart must return an ETag. This is not a valid call.");
            return;
          } else {
            parts.push({"PartNumber": nChunk + 1, "ETag": eTag});
          }
          nChunk += 1;
        }
        this.transferProgress = undefined;

        jwt_username = await this.authService.getToken();
        jwt = jwt_username[0];
        username = jwt_username[1];
        response = await this.ftapi.completeMultipartUpload(jwt, bucket, currFile.name, uploadId, parts);
      }

      if (response.status == 200) {
        let success_snackbar = this._snackbar.open("Successfully transferred " + currFile.name,
                                                      "x",
                                                      {
                                                      horizontalPosition: this.horizontalPosition,
                                                      verticalPosition: this.verticalPosition,
                                                      duration: 5 * 1000,
                                                      panelClass: ["success-snackbar"]
                                                      });
        success_snackbar.onAction().subscribe(() => {
          success_snackbar.dismiss();
          });

        this.scheduleRefresh(this.minRefreshDelay);
        // Load files in the background, don't await this.
        this.loadObjectVersions();
      } else {
        // This is an error response with a body.
        let isCloudflare = false;
        try {
          if(response.headers.get('Cf-Mitigated') !== null) {
            isCloudflare = true;
          }
        } catch {
          // empty
        } finally {
          if (isCloudflare) {
            this.formErrors.push(`Error transferring ${currFile.name}: Your file has failed the security policy. Please report this incident with the current time, or attempt resubmission from DSO premises.`);
          } else {
            try {
              let text = await response.text();
              let error = new XmlError(text);
              console.error("Error response from PutObject API: ", error);
              this.formErrors.push(`Error transferring ${currFile.name}: ${error.message} (request id: ${error.request_id})`);
            } catch (error: any) {
              const requestId = response.headers.get("x-request-id")
              console.error("Error was not XML", error);
              this.formErrors.push(`Unknown error transferring ${currFile.name}. (request id: ${requestId})`);
            }
          }
        }
      }
    } catch (error: any) {
      // Probably a network error. Most frequently caused by CONN_ABORTED after the file is too large.
      console.log("Error calling PutObject: ", error);
      this.formErrors.push(`There was a network error when transferring ${currFile.name}.`);
    } finally {
      attempt_snackbar.dismiss();
    }
  }

  async onClickSubmit() {
    let files_input = this.fileselectorVariable.nativeElement;
    if (files_input instanceof HTMLInputElement && files_input.files !== null) {
      for (const file of files_input.files) {
        await this.submitFileTransfer(file);
      }
    } else {
      console.error("file input is not an input?");
    }

    this.fileselectorVariable.nativeElement.value = '';
  }

  chunkFile(file: File) {
    let totalChunks = Math.ceil(file.size/this.chunksize);
    let chunk = 0;

    while (chunk < totalChunks) {
      let chunkStart = chunk * this.chunksize;
      let chunkEnd = (chunk + 1) * this.chunksize;
      console.log(chunk, '/', totalChunks, ': ', file.slice(chunkStart, chunkEnd));
      chunk++;
    }
  }

  // Schedules the page to be refreshed in delaySec seconds,
  // unless it is already going to be refreshed before that.
  //
  // This function can only make a refresh sooner.
  scheduleRefresh(delaySec: number): void {
    let delay = Math.max(delaySec, this.minRefreshDelay) * 1000;
    let scheduledTime = new Date(Date.now() + delay);
    if (this.nextRefresh < new Date(Date.now()) || scheduledTime < this.nextRefresh) {
      this.nextRefresh = scheduledTime;
      setTimeout(() => { this.refreshObjectVersions.next(0) }, delay + 100);
    }
  }

  // Check if the page should be refreshed, and refresh it if required.
  //
  // Returns whether the list was refreshed.
  // For some reason I can't get this function to refer to this,
  // although the methods that it calls clearly can.
  async autoLoadObjectVersions(ifAfter: Date): Promise<boolean> {
    if (ifAfter < new Date(Date.now())) {
      let recentPending = await this.loadObjectVersions();
      if (recentPending !== undefined) {
        let nextDelay = (Date.now() - recentPending.getTime()) * this.refreshBackoff / 1000;
        this.scheduleRefresh(nextDelay);
      }
      return true;
    } else {
      // otherwise ignore this refresh event, as it has been superseded by an earlier one.
      return false;
    }
  }

  // Forces a refresh of the ListObjectVersions.
  //
  // Returns the time of the most recent transaction in the pending state, or void.
  async loadObjectVersions(): Promise<Date | void> {
    try {
      await this.authService.updateToken();
    } catch (e) {
      console.error("Failed to refresh token: ", e);
      return;
    }

    let jwt_username = await this.authService.getToken();
    let jwt = jwt_username[0];
    let username = jwt_username[1];

    let files: FileSubmission[] = [];
    let recentPending = 0;
    for (const destNetwork of this.destNetworks) {
      let bucket = username + '.personal.' + destNetwork.value;
      let filesAtDestNetwork = await this.ftapi.getObjectVersions(jwt, bucket);
      if (filesAtDestNetwork.length === 1 && typeof(filesAtDestNetwork[0]) === 'string') {
        this.listVerErrormsgs.push(filesAtDestNetwork[0] + ' from ' + destNetwork.label);
      }
      else {
        for (const file of filesAtDestNetwork) {
          if (file.transfer_status == "PENDING") {
            recentPending = Math.max(recentPending, file.date);
          }
          files.push(file);
        }
      }
    }
    files.sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      } else {
        return -1;
      }
    });
    this.listVersions = new MatTableDataSource(files);
    this.listVersions.paginator = this.paginator;
    this.lastRefreshTime = new Date(Date.now());

    if (recentPending > 0) {
      return new Date(recentPending);
    }
  }
}
