import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { Observable, firstValueFrom } from "rxjs";

export const AUTH_SERVICE = 'AUTH_SERVICE';

@Injectable({
  providedIn: "root"
})
export class AuthService {

  constructor(private keycloakService: KeycloakService) {
  }

  public getToken(): Promise<string[]> {
    let tokenObs = new Observable<string[]>((subscriber) => {
      var jwt!: string;
      var username!: string;
      this.keycloakService.getToken().then((token: string) => {
        jwt = token;
        this.keycloakService.loadUserProfile().then((profile: KeycloakProfile) => {
          username = profile.username!;
          subscriber.next([jwt, username]);
          subscriber.complete();
        })
      });
    });
    return firstValueFrom(tokenObs);
  }

  public async updateToken(): Promise<boolean | void> {
    let result = await this.keycloakService.updateToken(-1);
    this.keycloakService.getToken().then((token) => {
      if (token === undefined) {
        alert("Your session has ended due to inactivity. This page will refresh.");
        this.keycloakService.login();
        throw("Restarting session");
        // no-return
      } else {
        console.log("Got a valid token, continuing.");
      }
    });
    return result;
  }
}
