import { Component, Input } from "@angular/core";

@Component({
    selector: "dso-nav-item",
    template: "",
})
export class NavItem {
    @Input() title!: string;
    @Input() href!: string;
    @Input() state: string = "";
    @Input() label!: string;
}