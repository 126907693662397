import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";

import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "auth";
import { AuthMsalModule } from "auth-msal";
import { DsoAngularModule } from 'dso-angular';
import { FtapiModule, FtapiService } from 'ftapi';
import { SubmitComponent } from './submit/submit.component';
import { environment } from "../environments/environment";

import { MsalBroadcastService, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";

@NgModule({
  imports: [
    AppRoutingModule,
    environment.msal_enabled
      ? AuthMsalModule.forRoot(environment.authmsalConfig)
      : AuthModule.forRoot(environment.keycloak_config),
    FtapiModule.forRoot(environment.ftapiConfig),
    BrowserModule,
    BrowserAnimationsModule,
    DsoAngularModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    environment.msal_enabled ? MsalModule : [],
  ],
  declarations: [
    AppComponent,
    SubmitComponent
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/portal/'},
    FtapiService,
    environment.msal_enabled ? MsalBroadcastService : []
  ],
  bootstrap: [
    AppComponent,
    environment.msal_enabled ? MsalRedirectComponent : [],
  ]
})

export class AppModule {
    
}