import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthmsalConfig } from './config';
import { AUTH_SERVICE, AuthMsalService } from './auth-msal.service';


@NgModule({
  declarations: [],
  imports: [
    MsalModule
  ]
})
export class AuthMsalModule {
  public static forRoot(authmsalconfig: AuthmsalConfig): ModuleWithProviders<AuthMsalModule> {
    return {
      ngModule: AuthMsalModule,
      providers: [
        {provide: authmsalconfig, useValue: authmsalconfig},
        {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
        {provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [authmsalconfig]},
        {provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory},
        {provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory},
        {provide: AUTH_SERVICE, useClass: AuthMsalService},
        MsalGuard,
        MsalBroadcastService,
        MsalService,
      ]
    };
  }
}

export function MSALInstanceFactory(authmsalconfig: AuthmsalConfig): IPublicClientApplication {
  return new PublicClientApplication({
    auth: authmsalconfig,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"],
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read", "user.read.all"]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
