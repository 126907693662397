import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Navbar } from './navbar/navbar.component';
import { NavItem } from './navbar/navitem.component';



@NgModule({
  declarations: [
    Navbar,
    NavItem,
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    MatToolbarModule,
  ],
  exports: [
    Navbar,
    NavItem,
  ]
})
export class DsoAngularModule { }
