import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export class FileSubmission {
    id: number;
    date: Date;
    source_network: string;
    dest_network: string;
    bucket: string;
    private _filename: string;
    transaction_hash: string;
    size: number;
    transfer_status: string;
    buttonstate: string;

    constructor(id: number,
                date: Date,
                source_network: string,
                dest_network: string,
                bucket: string,
                filename: string,
                transaction_hash: string,
                size: number,
                transfer_status: string,
                buttonstate: string) {
        this.id = id;
        this.date = date;
        this.source_network = source_network;
        this.dest_network = dest_network;
        this.bucket = bucket;
        this._filename = filename;
        this.transaction_hash = transaction_hash;
        this.size = size;
        this.transfer_status = transfer_status;
        this.buttonstate = buttonstate;
    }

    name_escape_uri(): string {
        return encodeURIComponent(this._filename);
    }

    name_escape_html(): string {
        return this._filename
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&#039;")
        .replace(/"/g, "&quot;")
    }

    name_raw(): string {
        return this._filename;
    }

    formatSize(): string {
        const units = ["B", "KiB", "MiB", "GiB"];
        let unit = 0;
        let size = this.size;
        while (size > 1024 && unit < (units.length - 1)) {
            size /= 1024;
            unit += 1;
        }
        if (unit == 0 || size > 999) {
            return size + " " + units[unit];
        } else {
            return size.toPrecision(3) + " " + units[unit];
        }
    }

    getObjectRef(): string {
        return this.name_escape_uri() + "?versionId=" + this.transaction_hash;
    }

    getRelativeDate(): string {
        dayjs.extend(relativeTime);
        return dayjs().to(dayjs(this.date));
    }
}
