export class StatusMessage {
    severity: string;
    message: string;

    constructor(severity: string,
                message: string) {
        this.severity = severity;
        this.message = message;
    }
}

export interface CompletedPart {
    ETag: string,
    PartNumber: number,
}
