import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { KeycloakConfig } from "keycloak-js";
import { AuthService, AUTH_SERVICE } from "./auth.service";

@NgModule({
  declarations: [],
  imports: [
    KeycloakAngularModule
  ]
})
export class AuthModule {
  public static forRoot(keycloakConfig: KeycloakConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: keycloakConfig,
          useValue: keycloakConfig
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializer,
          multi: true,
          deps: [KeycloakService, keycloakConfig],
        },
        {
          provide: AUTH_SERVICE,
          useClass: AuthService,
        }
      ]
    };
  }
}

import { KeycloakOptions } from "keycloak-angular";

export function initializer(keycloak: KeycloakService, config: KeycloakConfig): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: config,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    bearerExcludedUrls: []
  };
  return () => keycloak.init(options);
}
