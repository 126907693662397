import { InjectionToken } from "@angular/core";

export interface FtapiConfig {
    prefix: string,
}

export const defaultFtapiConfig: FtapiConfig = {
    prefix: "/",
};

export const FTAPI_CONFIG = new InjectionToken<FtapiConfig>("Config for the File Transfer API library");