import { ModuleWithProviders, NgModule } from '@angular/core';

import { FtapiService } from './ftapi.service';
import { FtapiConfig, FTAPI_CONFIG } from './config';

@NgModule({
  declarations: [],
  imports: [
  ],
})
export class FtapiModule {
  public static forRoot(ftapiConfig: FtapiConfig): ModuleWithProviders<FtapiModule> {
    return {
      ngModule: FtapiModule,
      providers: [
        {
          provide: FTAPI_CONFIG,
          useValue: ftapiConfig,
        },
        FtapiService,
      ]
    }
  }
}
