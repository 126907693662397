import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

import { FtapiService, ApiInfo } from 'ftapi';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'submit';

  // For API versioning for footer
  apiInfo!: ApiInfo;

  // For MSAL authentication
  isIframe: Boolean = false;
  loginDisplay: Boolean = false;
  private readonly _destroying$ = new Subject<void>;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private ftapi: FtapiService,
  ) {
  }

  ngOnInit(): void {
    this.apiInfo = new ApiInfo("", "");
    this.ftapi.getApiInfo().then(data => {
      this.apiInfo = data;
    });

    console.log("submit, in app.component.ts: environment.msal_enabled = ", environment.msal_enabled);

    this.isIframe = window !== window.parent && !window.opener;
    
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}