import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { Observable, firstValueFrom } from 'rxjs';

export const AUTH_SERVICE = 'AUTH_SERVICE';

@Injectable({
  providedIn: 'root'
})
export class AuthMsalService {

  accessTokenRequest = {
    scopes: ["user.read"],
    account: this.msalService.instance.getAllAccounts()[0],
  };

  constructor(private msalService: MsalService) { }

  public async updateToken(): Promise<Boolean | void> {
    // do nothing, MSAL handles updateToken()
    return true;
  }

  public getToken(): Promise<string[]> {
    let userSessionIdObs = new Observable<string[]>((subscriber) => {
      this.msalService.acquireTokenSilent(this.accessTokenRequest).subscribe({
        next: (accessTokenResponse: any) => {
          let jwt = accessTokenResponse.idToken;
          let upn = accessTokenResponse.idTokenClaims.upn;
          let index = upn.indexOf("dso.org.sg");
          if (index !== -1) {
            let username = upn.slice(0, index-1).toLowerCase();
            subscriber.next([jwt, username]);
            subscriber.complete();
          }
        },
        error: (e: any) => {
          console.error('auth-msal getToken error: ', e);
          if (e instanceof InteractionRequiredAuthError) {
            return this.msalService.acquireTokenRedirect(this.accessTokenRequest);
          } else {
            return "cannot get new token";
          }
        }
      })
    });
    return firstValueFrom(userSessionIdObs);
  }
}
