import { KeycloakConfig } from "keycloak-js";
import { FtapiConfig } from "ftapi";
import { AuthmsalConfig } from "auth-msal";

const keycloak_config: KeycloakConfig = {
    url: "https://keycloak.dso.org.sg:8443",
    realm: "DSONET",
    clientId: "DSOFTPv2"
};

const ftapiConfig: FtapiConfig = {
    prefix: "/",
}

const authmsalConfig: AuthmsalConfig = {
    clientId: "998c6be8-7e73-4c28-b0c6-a57c29fc427a",
    redirectUri: "https://put.transfer.dso.org.sg/portal",
    authority: "https://login.microsoftonline.com/965dc35e-5f7a-4e62-a71a-d3365f6b77cf"
  };

export const environment = {
    production: false,
    keycloak_config: keycloak_config,
    ftapiConfig: ftapiConfig,
    authmsalConfig: authmsalConfig,
    msal_enabled: true,
};