<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="needsRefresh" class="card alert alert-warning" role="alert">
    <mat-icon>error</mat-icon>
    Your session is about to expire or has expired.
    <a href="javascript:window.location.reload(true)">Please refresh this page to continue.</a>
</div>

<div *ngFor="let status of statuses" class="card alert alert-{{ status.severity }}" role="alert">
    {{ status.message }}
</div>

<div class="card">
    <h6>Transfer files</h6>
    <div class="">
        <div *ngIf="destNetworks.length > 1; else singledest">
            <label style="padding-bottom: 0.2em;">Send a file to</label>
            <mat-radio-group [(ngModel)]="destNetwork">
                <div *ngFor="let network of destNetworks">
                    <mat-radio-button [value]="network" [disabled]="!network.enabled">{{ network.label }}</mat-radio-button>
                </div>
            </mat-radio-group>
            <p class="form-text" style="clear: left; padding-top: 0.4em;">
                Retrieve files on {{ destNetwork.label }} at <span class="portal-link">{{ destNetwork.portalLink }}</span>.
            </p>
        </div>
        <ng-template #singledest>
            <label>Send a file to {{ destNetwork.label }}</label>
            <p class="form-text" style="clear: left;">
                Retrieve files on {{ destNetwork.label }} at <span class="portal-link">{{ destNetwork.portalLink }}</span>.
            </p>
        </ng-template>
    </div>

    <div>
        <div class="filepicker">
            <input #fileselector
            type="file"
            multiple
            class="mdc-button"
            id="fileselector"
            placeholder="Select file"
            (change)="selectFile($event)"/>
        </div>

        <div *ngIf="formErrors.length != 0; else selecthelp">
            <div class="form-text" style="color: red;">
                <p *ngFor="let error of formErrors">
                    {{ error }}
                </p>
            </div>
        </div>

        <ng-template #selecthelp>
            <div id="selectHelp" class="form-text">
                Files up to 50 GiB are accepted.<br>
                Encrypted archives may be rejected by the antimalware scan.
            </div>
        </ng-template>

    </div>

    <div *ngIf="transferProgress" class="my-3">
        <div class="form-text">
            Transfer of "{{ fileBeingTransferred }}" is in progress: ({{ transferProgress | number : '1.0-0' }}% complete)
        </div>
        <mat-progress-bar mode="determinate" [value]="transferProgress"></mat-progress-bar>
    </div>

    <div class="my-2">
        <input
            class="mdc-button mdc-button--raised"
            type="submit"
            value="Transfer"
            id="submit"
            (click) = "onClickSubmit()"/>
    </div>
</div>

<div class="card">

    <div class="headline transfer-log">
        <div class="d-inline-block">
            <h6>TechNet Transfer Log</h6>
            <div class="form-text">Transfers are kept for a maximum of five (5) days.</div>
        </div>
        <span class="spacer"></span>
        <div *ngIf="lastRefreshTime === null" class="subtext lastrefresh-subtext">
            <span class="secondary">Last refreshed:</span> never
        </div>
        <div *ngIf="lastRefreshTime !== null" class="subtext lastrefresh-subtext">
            <span class="secondary">Last refreshed:</span> {{ getRelativeLastRefresh() }}
        </div>
        <button
        class="mdc-button mdc-button--outlined d-block"
        (click)="loadObjectVersions()">
            <span class="mdc-button__ripple"></span>
            <mat-icon>refresh</mat-icon>
            Refresh
        </button>
    </div>

    <div *ngIf="listVerErrormsgs.length != 0" class="alert alert-danger" role="alert">
        <div *ngFor="let listVerErrormsg of listVerErrormsgs">
            <mat-icon>error</mat-icon> Error: {{ listVerErrormsg }}
        </div>
    </div>

    <div *ngIf="listVersions.filteredData.length == 0; else showtable">
        <div class="no-files">
            <img src="assets/nocontent.png" />
            <p>You have no files</p>
        </div>
    </div>

    <ng-template #showtable>
    <table mat-table [dataSource]="listVersions">
        <tr mat-header-row *matHeaderRowDef="listVersionsCols"></tr>
        <tr mat-row *matRowDef="let row; columns: listVersionsCols;"></tr>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef width="20%">Requested</th>
            <td mat-cell *matCellDef="let file">
                <div class="date-dual">
                    <span class="date-dual-main">{{ file.date.toLocaleString("en-SG") }}</span>
                    <span class="d-no-mobile subtext">{{ file.getRelativeDate() }}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="dest_network">
            <th mat-header-cell *matHeaderCellDef width="10%">To</th>
            <td mat-cell *matCellDef="let file">
                {{ file.dest_network }}
            </td>
        </ng-container>

        <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef width="48%">File</th>
            <td class="filename-truncate" mat-cell *matCellDef="let file">
                {{ file.name_escape_html() }}
            </td>
        </ng-container>

        <ng-container matColumnDef="filesize">
            <th mat-header-cell *matHeaderCellDef width="10%">Size</th>
            <td mat-cell *matCellDef="let file">{{ file.formatSize() }}</td>
        </ng-container>

        <ng-container matColumnDef="tx_status">
            <th mat-header-cell *matHeaderCellDef width="12%">Status</th>
            <td mat-cell *matCellDef="let file">
                <div *ngIf="file.transfer_status == 'PENDING'; else stat_approved"
                class="transfer-status transfer-status-pending">
                    <span #tooltip="matTooltip"
                    matTooltip="Your file transfer is pending.">
                    Pending
                </span>
            </div>

            <ng-template #stat_approved>
                <div *ngIf="file.transfer_status == 'APPROVED'; else stat_rejected"
                class="transfer-status transfer-status-approved">
                    <span #tooltip="matTooltip"
                    matTooltip="Please retrieve your files from the portal on {{ file.dest_network }}.">
                        Accepted
                    </span>
                </div>
            </ng-template>

            <ng-template #stat_rejected>
                <div *ngIf="file.transfer_status == 'REJECTED'; else stat_unknown"
                class="transfer-status transfer-status-rejected">
                    <span #tooltip="matTooltip"
                    matTooltip="This transfer was rejected. Raise an incident ticket to find out more.">
                        Rejected
                    </span>
                </div>
            </ng-template>

            <ng-template #stat_unknown>
                <div class="transfer-status transfer-status-unknown">
                    <span #tooltip="matTooltip"
                    matTooltip="Please report an issue.">
                        Unknown
                    </span>
                </div>
            </ng-template>
            </td>
        </ng-container>
    </table>
    </ng-template>

    <mat-paginator #paginator
                [pageSize]=getPageSizePreference()
                [pageSizeOptions]="[5,10,20,50]"
                [showFirstLastButtons]="true">
    </mat-paginator>
</div>
