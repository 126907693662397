<mat-toolbar class="navbar">
    <div class="branding">
        <div class="navbar-logo">
            <a href="{{ homepage }}" target="_blank">
                <img src="assets/dsologo.png" />
            </a>
        </div>

        <div class="app-title">
            {{ title }}
        </div>
    </div>

    <div class="navbar-tabs">
        <ng-container *ngIf="navItems !== undefined">
            <ng-container *ngFor="let item of navItems">
                <a class="navbar-item" [class.navbar-item-disabled]="item.state === 'disabled'" [class.navbar-item-active]="item.state === 'active'">
                    {{ item.title }}
                    <div *ngIf="item.label !== undefined" class="comingsoon">
                        {{ item.label }}
                    </div>
                </a>
            </ng-container>
        </ng-container>
    </div>

    <div class="spacer"></div>

    <div class="d-no-mobile">
        <a class="navbar-item" href="{{ existing }}" target="_blank">
            <div class="ftp-link">
                Revert to FTP
                <mat-icon style="transform: scale(0.7);">open_in_new</mat-icon>
            </div>
        </a>
    </div>

</mat-toolbar>
