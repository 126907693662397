import { xml2json } from 'xml-js';

export class XmlError {
    code: string;
    message: string;
    request_id: string;
    resource: string;

    // Parse the raw XML message
    constructor(message: string) {
        let error = JSON.parse(xml2json(message, {compact:true, spaces:4}));
        this.code = error["Error"]["Code"]["_text"];
        this.message = error["Error"]["Message"]["_text"];
        this.request_id = error["Error"]["RequestId"]["_text"];
        if (error["Error"]["Resource"] !== undefined) {
            this.resource = error["Error"]["Resource"]["_text"];
        } else {
            this.resource = "";
        }
    }
}
