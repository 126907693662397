import { Injectable } from "@angular/core";

export interface Network {
  value: string;
  label: string;
  portalLink: string;
  enabled: boolean;
}

const networks: Network[] = [
  {
    value: 'sc',
    label: 'OA/Project Net',
    portalLink: 'https://get.transfer.project.dso/portal/',
    enabled: false
  },
  {
    value: 'cf',
    label: 'DSNet',
    portalLink: 'https://get.transfer.dsnet.dso.root/portal',
    enabled: false
  },
  {
    value: 'rt',
    label: 'TechNet',
    portalLink: 'https://get.transfer.dso.org.sg/portal',
    enabled: false
  },
];

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  getNetwork(networkValue: string): Network {
    let network = networks.find(({ value }) => value === networkValue);
    if (network === undefined) {
      return networks[0];
    } else {
      return network;
    }
  }

  /**
   * Given a network, returns the other networks,
   * e.g., if the network is rt, otherNetworks will be cf and sc.
   */
  getOtherNetworks(networkValue: string): Network[] {
    return networks.filter(({ value }) => value !== networkValue);
  }

  enabledNetworks(networkValue: string, enabledNetworkValues: string[]): Network[] {
    const otherNetworks = this.getOtherNetworks(networkValue);
    otherNetworks.forEach((network) => {
      if (enabledNetworkValues.includes(network.value)) {
        network['enabled'] = true;
      }
    });
    return otherNetworks;
  }

  getNetworkLabel(networkValue: string): string {
    let network = networks.find(({ value }) => value === networkValue);
    return network ? network.label : '';
  }
}