export class ApiInfo {
    commit: string
    version: string;

    constructor(
        commit: string,
        version: string) {
        this.commit = commit;
        this.version = version;
    }
}