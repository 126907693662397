import { Component, ContentChildren, Input, QueryList } from "@angular/core";

import { NavItem } from "./navitem.component";

export interface NavbarConfig {
    elements?: Array<NavbarElement>;
    elements_right?: Array<NavbarElement>;
}

export interface NavbarElement {
  title: string;
  label?: string;
  state?: string;
  href: string;
}

@Component({
    selector: "dso-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.css"],
})
export class Navbar {
    @Input() title!: string;
    @Input() homepage!: string;
    @Input() existing!: string;
    @ContentChildren("navItem") navItems!: QueryList<NavItem>;
}
